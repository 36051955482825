import React, { useState } from 'react'
import Login from '../authentication/login/login'
import Signup from '../authentication/signup/signup'
import Footer from '../shared/footer/footer'
import Header from '../shared/header/header'

const Pricing = () => {
    const [state, setState] = useState({
        popupVisible: false,
        isLoginModalVisible:false,
        isSignupModalVisible:false,
        text: null,
      })
      const handleLoginModal = (bool) => { setState({...state, isLoginModalVisible : bool }) }

      const handleSignupModal = (bool) => { setState({...state, isSignupModalVisible: bool }) }
    
  return (
    <>
    <Header handleLoginModal={handleLoginModal} handleSignupModal={handleSignupModal} />
    <Login isModalVisible={state.isLoginModalVisible} handleModal={handleLoginModal} />
    <Signup isModalVisible={state.isSignupModalVisible} handleModal={handleSignupModal} />


  <section className="home-banner work-banner">

    <img src="../images/pricing-banner.jpg" alt=""/>


    <div className="tag">
      <h1>Fully automate</h1>
      <h2>growing your fanbase</h2>

    </div>

  </section>




  <section className="aboutus background1">

    <div className="container">



      <div className="feature_boxes row_parent">

        <div className="row flex1 mb-10">

          <div className="col_left">
            <div className="heads">

              <h3>We do the work</h3>
              <h4>so you don't have to</h4>
              <span className="line"></span>
            </div>

            <p>Go back to playing music and build your fanbase with Fanify.</p>


          </div>

          <div className="col_right">
            <div className="img">
              <img src="../images/work.jpg" alt=""/>
            </div>


          </div>

        </div>


        <div className="row flex1 mb-10">

          <div className="col_left">
            <div className="heads">

              <h3>The world's most powerful</h3>
              <h4> music promotion service</h4>
              <span className="line"></span>
            </div>

            <p>AI driven machine learning technology.</p>
          </div>

          <div className="col_right">

            <div className="img">
              <img src="../images/services.jpg" alt=""/>
            </div>




          </div>

        </div>



      </div>


    </div>
  </section>


  <section className=" background1 pricing">

    <div className="container">

      <div className="heads" id="pplans">

        <h3>Pricing plan</h3>
        <h4>Choose your best option</h4>
        <span className="line"></span>
      </div>


      <div className="offer" id="premium">
        <p><strong>BASIC Fanify </strong> offers amazing music promotional tools for free! </p>

        <p> <strong>PREMIUM </strong>Sign up to a subscription to unlock your full potential and grow your fanbase!</p>


        <p id="enterprise" ><strong>ENTERPRISE</strong> Contact us today to connect your record label, media company or digital service provider to Fanify’s New Music Audience technology.</p>
      </div>

      <div className="unque-table offer-table mt-5" id="gopricing">

        <table className="table">
          <thead>
            <tr>
              <th scope="col"> </th>
              <th scope="col">Basic</th>
              <th scope="col">PREMIUM</th>
              <th scope="col">ENTERPRISE</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">Smart Link</th>
              <td><img src="../images/right-check.png" width="" alt=""/></td>
              <td><img src="../images/right-check.png" width="" alt=""/></td>
              <td><img src="../images/right-check.png" width="" alt=""/></td>
            </tr>
            <tr>
              <th scope="row">Bio Link</th>
              <td><img src="../images/right-check.png" width="" alt=""/></td>
              <td><img src="../images/right-check.png" width="" alt=""/></td>
              <td><img src="../images/right-check.png" width="" alt=""/></td>
            </tr>
            <tr>
              <th scope="row">Automated music promotion</th>
              <td><img src="../images/cros.png" width="" alt=""/></td>
              <td><img src="../images/right-check.png" width="" alt=""/></td>
              <td><img src="../images/right-check.png" width="" alt=""/></td>
            </tr>
            <tr>
              <th scope="row">Tagline generator & soundlikes</th>
              <td><img src="../images/cros.png" width="" alt=""/></td>
              <td><img src="../images/right-check.png" width="" alt=""/></td>
              <td><img src="../images/right-check.png" width="" alt=""/></td>
            </tr>
            <tr>
              <th scope="row">Ranking & optimisation system</th>
              <td><img src="../images/cros.png" width="" alt=""/></td>
              <td><img src="../images/right-check.png" width="" alt=""/></td>
              <td><img src="../images/right-check.png" width="" alt=""/></td>
            </tr>

            <tr>
              <th scope="row">AI audience targeting</th>
              <td><img src="../images/cros.png" width="" alt=""/></td>
              <td><img src="../images/right-check.png" width="" alt=""/></td>
              <td><img src="../images/right-check.png" width="" alt=""/></td>
            </tr>

            <tr>
              <th scope="row">Unlimited artists</th>
              <td><img src="../images/cros.png" width="" alt=""/></td>
              <td><img src="../images/cros.png" width="" alt=""/></td>
              <td><img src="../images/right-check.png" width="" alt=""/></td>
            </tr>

            <tr>
              <th scope="row">Unlimited users</th>
              <td><img src="../images/cros.png" width="" alt=""/></td>
              <td><img src="../images/cros.png" width="" alt=""/></td>
              <td><img src="../images/right-check.png" width="" alt=""/></td>
            </tr>

            <tr>
              <th scope="row">Dedicated account manager</th>
              <td><img src="../images/cros.png" width="" alt=""/></td>
              <td><img src="../images/cros.png" width="" alt=""/></td>
              <td><img src="../images/right-check.png" width="" alt=""/></td>

            </tr>

            <tr>
              <th scope="row"> &nbsp;</th>
              <td><a href="#" className="btn">FREE</a></td>
              <td><a href="#" className="btn">$14.99/MONTH</a></td>
              <td><a href="#" className="btn">CONTACT US</a></td>

            </tr>

          </tbody>
        </table>

      </div>


    </div>

  </section>
  <Footer />
    </>
  )
}

export default Pricing